import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {Index1Component} from './index1/index1.component';
import {Index10Component} from './index10/index10.component';
import {Index11Component} from './index11/index11.component';
import {Index12Component} from './index12/index12.component';
import {Index13Component} from './index13/index13.component';
import {Index2Component} from './index2/index2.component';
import {Index3Component} from './index3/index3.component';
import {Index4Component} from './index4/index4.component';
import {Index5Component} from './index5/index5.component';
import {Index6Component} from './index6/index6.component';
import {Index7Component} from './index7/index7.component';
import {Index8Component} from './index8/index8.component';
import {Index9Component} from './index9/index9.component';
import {LoginComponent} from './login/login.component';
import {PasswordForgetComponent} from './password-forget/password-forget.component';
import {SignupComponent} from './signup/signup.component';
import {CdiPrivacyPolicyComponent} from './cdi-privacy-policy/cdi-privacy-policy.component';

const routes: Routes = [
    // {
    //     path: '**',
    //     redirectTo: '/'
    // },
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
    },
    {
        path: 'index-1',
        component: Index1Component
    },
    {
        path: 'index-2',
        component: Index2Component
    },
    {
        path: 'index-3',
        component: Index3Component
    },
    {
        path: 'index-4',
        component: Index4Component
    },
    {
        path: 'index-5',
        component: Index5Component
    },
    {
        path: 'index-6',
        component: Index6Component
    },
    {
        path: 'index-7',
        component: Index7Component
    },
    {
        path: 'index-8',
        component: Index8Component
    },
    {
        path: 'index-9',
        component: Index9Component
    },
    {
        path: 'index-10',
        component: Index10Component
    },
    {
        path: 'index-11',
        component: Index11Component
    },
    {
        path: '',
        component: Index12Component
    },
    {
        path: 'index-13',
        component: Index13Component
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'password_forget',
        component: PasswordForgetComponent
    },
    {
        path: 'signup',
        component: SignupComponent
    },
    {
        path: 'cdi-privacy',
        component: CdiPrivacyPolicyComponent
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {
}
