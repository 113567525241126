import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})

/**
 * Services component
 */
export class ServicesComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    servicesData = [
        {
            icon: 'ti-settings',
            title: 'Software Development',
            description: 'we can develop reliable, scalable and secure software solutions for any OS, browser and device.'
        },
        {
            icon: 'ti-palette',
            title: 'UX/UI Design',
            description: 'User experience and user interface design for all types of websites, SaaS, and web/mobile apps. We combine the latest UI/UX trends with our customers’ individual goals and needs.'
        },
        {
            icon: 'ti-stats-up',
            title: 'Data Analytics',
            description: 'We support businesses in achieving fact-based decision-making by converting their historical and real-time, traditional and big data into actionable insights.'
        },
        {
            icon: 'ti-shield',
            title: 'Cybersecurity Services',
            description: 'Information security and employing ISO 27001 certified information security management practices, we help to achieve the robust protection of the companies’ applications and networks.'
        },
        {
            icon: 'ti-headphone',
            title: 'IT Consulting',
            description: 'Our experts can help to develop and implement an effective IT strategy, assist in smooth digital transformation and system integration as well as advise on improvements to your digital customer experience.'
        }
    ];

}
